import ThemeIcon from "../Icon/ThemeIcon";
import { ThemeProps } from "../../pages/app/Nav";
import Button from "./Button";

export default function DarkModeButton({ theme, updateTheme }: ThemeProps) {

    const handleSwitchTheme = () => {
        const newTheme = theme === "light" ? "dark" : "light"
        updateTheme(newTheme)
    }

    return (
        <Button
            id={theme}
            onClick={handleSwitchTheme}
            classList={`btn ${theme} theme-button`}
            ariaLabel="Toggle theme"
        >
            <ThemeIcon theme={theme} />
        </Button>
    )
}