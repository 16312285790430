import styled from "styled-components"
import { Link } from "../../enums/links"
import Item from "./Item"

type ListProps = {
    links: Link[]
    className?: string
}

export default function List({ links, className }: ListProps) {
    return (
        <ListStyled>
            <div>
                <ul className={className}>
                    {links.map(({ name, url, icon }) => {
                        return (
                            <Item key={url} name={name} url={url} icon={icon} />
                        )
                    })}
                </ul>
            </div>
        </ListStyled>
    )
}

const ListStyled = styled.section`
    ul {
        box-sizing: border-box;
        list-style: none;
        margin: 0 auto;
        padding: 2em;
        max-width: 480px;

        li {
            border: none;

            a {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 1em;
            }
        }
    }
`