import React from "react";
import "../../../App.css"

export interface ButtonProps {
    id?: string
    classList?: string
    ariaLabel?: string
    preset?: 'primary' | 'secondary'
    type?: 'btn-outline-' | 'clear' | 'outline'
    size?: number | 'btn-sm' | 'normal' | 'small' | 'auto'
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean
    hitSlop?: number
    theme?: string
    icon?: React.Component | React.FunctionComponent
    border?: string
    color?: string
    height?: string
    radius?: string
    width?: string
    children?: React.ReactNode
}

export default function Button({
    id,
    classList,
    ariaLabel,
    type = 'btn-outline-',
    size = 'btn-sm',
    onClick,
    disabled = false,
    theme,
    icon,
    border,
    color,
    height,
    radius,
    width,
    children,
}: ButtonProps) {

    return (
        <button
            id={id}
            onClick={onClick}
            type="button"
            className={`btn ${size} ${type} ${theme} ${classList} `}
            aria-label={ariaLabel}
            style={{
                backgroundColor: color,
                border,
                borderRadius: radius,
                height,
                width
            }}
            disabled={disabled}
        >
            {icon && (
                <svg
                    viewBox="0 0 24 24"
                    focusable="false"
                    className="chakra-icon css-onkibi"
                    aria-hidden="true"
                >
                    <path
                        fill="currentColor"
                        d="M21.4,13.7C20.6,13.9,19.8,14,19,14c-5,0-9-4-9-9c0-0.8,0.1-1.6,0.3-2.4c0.1-0.3,0-0.7-0.3-1 c-0.3-0.3-0.6-0.4-1-0.3C4.3,2.7,1,7.1,1,12c0,6.1,4.9,11,11,11c4.9,0,9.3-3.3,10.6-8.1c0.1-0.3,0-0.7-0.3-1 C22.1,13.7,21.7,13.6,21.4,13.7z"
                    ></path>
                </svg>
            )}
            {children}
        </button>
    );
}