import { PropsWithChildren } from "react";
import "../../../App.css"

export interface ButtonGroupProps {
    id?: string
    size?: 'sm' | 'lg'
    disabled?: boolean
    ariaLabel?: string
    theme?: string
    border?: string
    color?: string
    height?: string
    radius?: string
    right?: number
    width?: string
}

export default function ButtonGroup({
    size,
    ariaLabel,
    radius,
    right,
    children,
}: PropsWithChildren<ButtonGroupProps>) {

    return (
        <div
            aria-label={ariaLabel}
            role="group"
            className={`action-btn btn-group ${size && 'btn-group-' + size}`}
            style={{
                borderRadius: radius,
                right
            }}
        >
            {children}
        </div>
    );
}