import { ForwardedRef, forwardRef } from "react"

type HeaderProps = {
    className?: string
}

const Header = forwardRef(({ className = "" }: HeaderProps, ref: ForwardedRef<HTMLInputElement | null>) => {
    return (
        <header className={`animated ` + className} ref={ref}>
            <img src="./img/kentucky.JPG" alt="kentucky" />
            <h1>Kentucky</h1>
            <h2>ReactJS / PHP Fullstack Developer</h2>
        </header>
    );
})

export default Header;
