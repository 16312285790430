import DarkModeButton from "../../shared/Button/DarkModeButton";
import ShareModal from "../../shared/Modal/ShareModal";
import ButtonGroup from "../../shared/Button/ButtonGroup";
import styled from "styled-components";

declare type Setter<T> = React.Dispatch<React.SetStateAction<T | undefined>>;

export type ThemeProps = {
    theme: string
    updateTheme: Setter<string>
}

export default function Nav({ theme, updateTheme }: ThemeProps) {
    return (
        <NavStyled>
            <ButtonGroup ariaLabel="Actions Buttons" size="lg">
                <ShareModal theme={theme} modalTitle="Share" size="sm" />
                <DarkModeButton theme={theme} updateTheme={updateTheme} />
            </ButtonGroup>
        </NavStyled>
    );
}

const NavStyled = styled.nav`
    grid-area: nav;
    grid-template-columns: 80px;
    grid-template-rows: 40px;
    justify-items: center;
    justify-self: center;
    align-items: center;
`;