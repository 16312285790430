export const shareLinks = [
    {
        name: "Linkedin",
        url: "https://www.linkedin.com/sharing/share-offsite/?url=https://links.ksato.fr",
        icon: "/img/icon/linkedin-in-brands.svg",
    },
    {
        name: "Email",
        url: "mailto:?subject= Check out Kentucky Bio Links! &amp;body= Check out Kentucky Bio Links! - https://links.ksato.fr",
        icon: "/img/icon/email.svg",
    },
    // {
    //     name: 'Facebook',
    //     url: 'https://www.facebook.com/sharer.php?u=https://links.ksato.fr',
    //     icon: '/img/icon/github-brands.svg'
    // },
    // {
    //     name: 'Twitter',
    //     url: 'https://twitter.com/intent/tweet?text=Check out Kentucky Bio Links!%20-%20https://links.ksato.fr',
    //     icon: '/img/icon/stackoverflow-color-icon.svg'
    // },
    // {
    //     name: 'WhatsApp',
    //     url: 'https://www.youtube.com/channel/UCStzoaDbafhOeyp37rftecQ',
    //     icon: '/img/icon/youtube-brands.svg'
    // },
    // {
    //     name: 'Messenger',
    //     url: 'https://www.messenger.com/new',
    //     icon: '/img/icon/instagram-brands.svg'
    // },
]
