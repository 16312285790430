import useLocalStorage from 'use-local-storage';
import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import "../../../App.css"
import List from '../../shared/List';
import { websites } from './websiteConfig';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

export default function App() {

    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const headerRef = useRef(null);
    const appRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (headerRef.current) {
                headerRef.current.style.opacity = '1';
                headerRef.current.style.filter = 'blur(0px)';
            }

            if (appRef.current) {
                appRef.current.style.opacity = '1';
                appRef.current.style.filter = 'blur(0px)';
            }
        }, 60);
    }, []);

    return (
        <AppStyled id="App" className="App animated" data-theme={theme} ref={appRef}>
            <Nav theme={theme} updateTheme={setTheme} />
            <Header ref={headerRef} />
            <List links={websites} className='websiteList' />
            <Footer />
        </AppStyled>
    );
}

const AppStyled = styled.div`
    &.App{
        display: grid;
        grid-template-areas:
            "header nav"
            "section section"
            "footer footer";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;

        text-align: center;
        color: var(--text-primary);
        background-color: var(--background);
        padding: 20px;
        transition: all 0.5s;
        min-height: 100vh;

        &.animated {
            opacity: 0;
            filter: blur(10px);
            transition: opacity 2s ease-in-out, filter 2s ease-in-out;
        }
    }
`;
