import { PropsWithChildren, useState } from "react";
import Button from "../Button/Button";
import List from "../List";
import { shareLinks } from "../../pages/app/shareLinksConfig";
import ShareIcon from "../Icon/ShareIcon";

export interface ModalProps {
    modalTitle: string
    size?: 'sm' | 'lg'
    theme?: string
}

export default function ShareModal({ modalTitle, theme, size }: PropsWithChildren<ModalProps>) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleSharing = function () {

        if (navigator.share) {
            try {
                navigator.share({
                    title: document.title,
                    text: 'See Kentucky Bio Links',
                    url: 'https://links.ksato.fr',
                })
                    .then(() => console.log("Hooray! Your content was shared to tha world"))
                    .catch((e) => (console.log('fail')));
            } catch (error) {
                console.log(`Oops! I couldn't share to the world because: ${error}`);
            }
        } else {
            // fallback code
            console.log(
                "Web share is currently not supported on this browser."
            );
            setShow(true);
        }
    };

    return (
        <>
            <Button
                id="ShareButton"
                data-testid="ShareButton"
                onClick={handleSharing}
                classList={`share-button ${theme}`}
                ariaLabel="Share"
            >
                <ShareIcon theme={theme} />

            </Button>
            {
                show &&
                <div className="fade modal-backdrop show"></div>
            }
            <div
                role="dialog"
                aria-modal="true"
                className={`fade modal show`}
                tabIndex={-1}
                style={{ display: show ? 'block' : 'none' }}
                onClick={handleClose}
            >
                <div className={`modal-dialog modal-dialog-centered modal-${size ? size : ''}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4 center">{modalTitle}</div>
                            <Button
                                onClick={handleClose}
                                classList={`btn-close`}
                                ariaLabel="Close"
                            ></Button>
                        </div>
                        <div className="modal-body">
                            <List className={"shareList"} links={shareLinks} />
                        </div>
                        {/* <div className="modal-footer">
                            <Button
                                theme={'primary'}
                                onClick={handleClose}
                            >Fermer</Button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
