import { Link } from "../../enums/links";

export default function Item({ name, url, icon }: Link) {
    return (
        <li className="bounce-out-on-hover">
            <a target="_blank" rel="noopener noreferrer" href={url} aria-disabled="true">
                <img src={icon} alt={name} style={{ height: 30, width: 30 }} />
                <span>{name}</span>
            </a>
        </li>
    )
}
