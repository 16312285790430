export const websites = [
    // {
    //     name: 'ksato',
    //     url: 'https://ksato.fr',
    //     icon: '/img/icon/house-solid.svg'
    // },
    {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/kentuckysato/",
        icon: "/img/icon/linkedin-in-brands.svg",
    },
    {
        name: "Gitlab",
        url: "https://gitlab.com/kentuckyss",
        icon: "/img/icon/gitlab-icon.svg",
    },
    {
        name: "Github",
        url: "https://github.com/KentuckySato",
        icon: "/img/icon/github-brands.svg",
    },
    {
        name: "StackOverflow",
        url: "https://stackoverflow.com/users/3127504/kentuckyss",
        icon: "/img/icon/stackoverflow-color-icon.svg",
    },
    {
        name: "YouTube",
        url: "https://www.youtube.com/channel/UCStzoaDbafhOeyp37rftecQ",
        icon: "/img/icon/youtube-brands.svg",
    },
    {
        name: "Instagram",
        url: "https://www.instagram.com/kentuckyss/",
        icon: "/img/icon/instagram-brands.svg",
    },
]
